<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import { trim } from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import structuredData from '@core/mixins/structuredData';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { SITE_INT_ID_WWW } from '@model/const/sites';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage, getTextDirection, getHtmlLang } from '@utils/html-meta';
import { resolveDataFrom } from '@utils/resolve-data-from';
import PagesComponent from './component.vue';

const API_SITE_ID = 1;

export default Vue.extend({
    name: 'PagesContainer',

    mixins: [structuredData],

    async serverPrefetch(): Promise<void> {
        // Init
        const { locale, pathMatch: slug, previewId } = this.$route.params;
        const state = this.$store.state.pages;

        // Disable LB cache for preview
        if (this.previewKey || previewId) {
            this.$ssrContext.res.headers.push({ key: 'Cache-Control', val: 'no-store' });
        }

        // Get page
        const pageRequestUrl = previewId ? `/api/utility/cache/${previewId}?time=${Date.now()}` : '/api/core/pages';
        const pageRequest = new ProductAPIQueryBuilder('page');
        pageRequest.setEntityPath(pageRequestUrl);

        if (!previewId) {
            pageRequest
                .setLocales([locale])
                .addMatchesAll('slug', '=', trim(slug, '/'))
                .addMatchesAll('website_id', '=', SITE_INT_ID_WWW.toString())
                .setPaginate(1, 1)
                .setCustomParam('page', this.previewKey ? { key: this.previewKey } : null)
                .setCustomParam('process-macros', '1');
        }

        const requestObj = pageRequest.toObject();
        await this.$store.dispatch('pages/getPage', { request: requestObj });

        if (state.httpStatus !== StatusCodes.OK) {
            throw new HttpRejection(`Premature rendering stop: ${state.httpStatus}`, state.httpStatus);
        }

        if (!state.page?.id || !state.page?.body?.length) {
            throw new HttpRejection(`Premature rendering stop: ${StatusCodes.NOT_FOUND}`, StatusCodes.NOT_FOUND);
        }

        const enrichedPageBody = await resolveDataFrom(
            state.page.body,
            locale,
            this.$ssrContext.Synced,
        );

        await this.$store.dispatch('pages/modifyPageBody', enrichedPageBody);

        // Get linked entities
        const promises: Promise<void>[] = [];

        if (!state.page.settings?.hide_ribbon) {
            promises.push(this.$store.dispatch('ribbon/getAll', {
                locale,
                slug: state.page.slug,
                siteID: API_SITE_ID,
            }));
        }

        if (!state.page.settings?.hide_popup) {
            promises.push(this.$store.dispatch('popup/getAll', {
                locale,
                slug: state.page.slug,
                siteID: API_SITE_ID,
            }));
        }

        const audiencesQuery = new ProductAPIQueryBuilder('audiences')
            .setEntityPath('/api/core/pages/audiences/')
            .setPaginate(1, 50)
            .toObject();

        const typesQuery = new ProductAPIQueryBuilder('types')
            .setEntityPath('/api/core/pages/types/')
            .setPaginate(1, 50)
            .toObject();

        this.$store.dispatch('pages/getEntity', { request: audiencesQuery });
        this.$store.dispatch('pages/getEntity', { request: typesQuery });
        this.$store.commit('pages/setVwoTests', state.page.settings?.vwo_ab_test_enabled);
        this.$store.dispatch('pages/setLoading', !!state.page.settings?.vwo_ab_test_enabled);
        this.$store.commit('pages/setVwoCheckInterval', state.page.settings?.vwo_check_interval);
        this.$store.commit('pages/setVwoCheckAttempts', state.page.settings?.vwo_check_attempts);

        promises.push(
            this.$store.dispatch('slices/getSyncedData', { slice: 'global-meta', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('seo/getSyncedData', { key: 'routes-config', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-website', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-webpage', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-organization', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-contact-point', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-offer', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-postal-address', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-video-object', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-faq-page', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-breadcrumbs', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-job-posting', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-product', locale }),
            this.$store.dispatch('products/getProducts', locale),
        );

        if (!state.page.settings?.hide_ribbon) {
            promises.push(this.$store.dispatch('ribbon/getAll', {
                locale,
                slug: state.page.slug,
                siteID: SITE_INT_ID_WWW,
            }));
        }

        if (!state.page.settings?.hide_popup) {
            promises.push(this.$store.dispatch('popup/getAll', {
                locale,
                slug: state.page.slug,
                siteID: SITE_INT_ID_WWW,
            }));
        }

        await Promise.all(promises);

        // Building page meta
        this.$ssrContext.res.meta = await this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapGetters('seo', ['getRoutesConfig', 'getStaticConfigs']),
        previewKey() {
            return this.$route.query?.key;
        },
    },

    methods: {
        async getMeta(): Promise<any> {
            const page = this.$store.state.pages.page;
            const { locale } = this.$route.params;

            const ensureTrailingSlash = (input: string) => input.replace(/\/*?$/, '/');

            const self = ensureTrailingSlash(`https://${this.$config.domain}${this.$route.path}/`);
            const meta: any = page.meta || {};
            const canonical = meta.canonical ? ensureTrailingSlash(meta.canonical) : self;
            const globalMeta = this.$store.state.slices.items['global-meta'] || {};
            const imgSrc = meta.image || `@${globalMeta.defaultOgImage}`;
            const ogimage = getOgImage(imgSrc, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = meta.title || 'Acronis';
            const description = meta.description || title;
            let isIndexed = page.is_indexed;

            if (this.previewKey) isIndexed = false;

            const ldJsonSchema = await this.getStructuredDataMarkup({
                siteID: this.$config.siteID,
                domain: this.$config.domain,
                schemasConfig: this.getStaticConfigs,
                routesConfig: this.getRoutesConfig,
            });

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: meta['og:description'] || description },
                    { tag: 'meta', property: 'og:image', content: ogimage },
                    { tag: 'meta', property: 'og:url', content: self },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    {
                        tag: 'meta',
                        name: 'twitter:description',
                        content: meta['twitter:description'] || description,
                    },
                    { tag: 'meta', name: 'twitter:image', content: ogimage },
                    { tag: 'meta', name: 'twitter:url', content: self },
                    isIndexed ? null : { tag: 'meta', name: 'robots', content: 'noindex, nofollow' },
                    { tag: 'link', rel: 'image_src', href: ogimage },
                    isIndexed ? { tag: 'link', rel: 'canonical', href: canonical } : null,
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
                ldJsonSchema,
            };
        },
    },

    render(h) {
        const props = {
            isPreview: Boolean(this.previewKey),
        };
        return h(PagesComponent, { props });
    },
});
</script>
